<div class="container">
    <app-top-banner></app-top-banner>
    <app-tripura-featured></app-tripura-featured>
    <app-gallery></app-gallery>
</div>
<app-india-news></app-india-news>
<app-featured-video></app-featured-video>
<div class="container">
    <app-fashion-lifestyle-sports></app-fashion-lifestyle-sports>
</div>
<app-world-news></app-world-news>
<app-article></app-article>