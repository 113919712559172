import { Component, OnInit } from '@angular/core';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.css'],
})
export class TopBannerComponent implements OnInit {
  postData = {
    Business: [],
    Fashion: [],
    Travel: [],
    Sports: [],
    Students: [],
    Nature: [],
    Tripura: [],
    'Breaking News': [],
    'Tripura Featured': [],
  };

  blockType = {
    Business: '',
    Fashion: '',
    Travel: '',
    Sports: '',
    Students: '',
    Nature: '',
    Tripura: '',
    'Breaking News': '',
    'Tripura Featured': '',
  };

  loader = true;

  constructor(
    private fetchPostDataService: FetchPostDataService,
    private filePathsService: FilePathsService
  ) {}

  ngOnInit(): void {
    this.fetchPostDataService.blockpostdata.subscribe((x) => {
      if (x) {
        this.getPostdata();
        this.loader = false;
      }
    });
  }

  getPostdata(data = this.fetchPostDataService.postdataValue) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]['block_posts'] != null) {
        let posts = this.fetchPostDataService.arrayConvertion(
          data[i]['block_posts']
        );
        for (let j = 0; j < posts.length; j++) {
          if (posts[j]['images'] != null) {
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(
              posts[j]['images']
            );
          }
          if (posts[j]['videos'] != null) {
            posts[j]['videos'] = this.fetchPostDataService.arrayConvertion(
              posts[j]['videos']
            );
            if (posts[j]['video_category'] == 2) {
              posts[j]['videos'][0]['video_name'] =
                this.filePathsService.youtube_parser(
                  posts[j]['videos'][0]['video_name']
                );
            }
          }
          this.postData[data[i]['block_name']].push(posts[j]);
        }
      } else {
        this.postData[data[i]['block_name']] = null;
      }
      this.blockType[data[i]['block_name']] = data[i]['block_type'];
    }
  }
}
