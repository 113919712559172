import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FetchPostDataService } from '../services/fetch-post-data.service';
import { MetaService } from '../services/meta-service.service';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.css'],
})
export class CategoryPageComponent implements OnInit {
  slug_url = '';
  post_slides = [];
  album_slides = [];
  video_slides = [];
  categoryName = '';
  pos_1 = [];
  pos_2 = [];
  count_of_post = 0;
  constructor(
    private route: ActivatedRoute,
    private fetchPostDataService: FetchPostDataService,
    private meatService: MetaService
  ) {
    this.route.params.subscribe((params: Params) => {
      this.slug_url = params['slug_url'];

      this.fetchPostDataService
        .getCategoryData(this.slug_url)
        .subscribe((data) => {
          this.meatService.updateTitle(data.meta_title);
          this.meatService.updateMetaInfo(data.meta_description, data.keywords);
        });

      this.fetchPostDataService.categorypostdata.subscribe((x) => {
        if (x) {
          this.getCategorydata();
        }
      });
    });
  }

  ngOnInit(): void {}

  getCategorydata(data = this.fetchPostDataService.categorypostdataValue) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]['slug_url'] == this.slug_url) {
        
        let postData = [];
        let videoData = [];
        let albumData = [];
        let position_1 = [];
        let position_2 = [];
        
        this.categoryName = data[i]['name'];
        
        let posts = this.fetchPostDataService.arrayConvertion(
          data[i]['category_posts']
        );

        this.count_of_post = posts.length;

        for (let j = 0; j < posts.length; j++) {
          if (posts[j]['images'] != null) {
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(
              posts[j]['images']
            );
          }

          if(posts[j]['id']==data[i]['pos_1']){
            position_1.push(posts[j]);
            continue;
          }

          if(posts[j]['id']==data[i]['pos_2']){
            position_2.push(posts[j]);
            continue;
          }

          if(posts[j]['post_type']=='post'){
            postData.push(posts[j]);
          }
          else if(posts[j]['post_type']=='album'){
            albumData.push(posts[j]);
          }
          else{
            videoData.push(posts[j]);
          }
        }

        if(position_1.length==0 && postData.length){
          position_1.push(postData.shift());
        } 
        if(position_2.length==0 && postData.length){
          position_2.push(postData.shift());
        }

        this.post_slides = postData;
        this.album_slides = albumData;
        this.video_slides = videoData;
        this.pos_1 = position_1;
        this.pos_2 = position_2;
      }
    }

    this.fetchPostDataService.getNavMenu().subscribe((data) => {
      document.querySelector('#add-snippet').innerHTML = data.add_snippet;
    });
  }
}
