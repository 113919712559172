<!-- <h1>this is slider block</h1> -->

<owl-carousel-o [options]="customOptions" *ngIf="slides != null">
  <ng-container *ngFor="let slide of slides; index as i">
    <ng-template 
      carouselSlide [id]="i"
      *ngFor="let img of slides[i].images; index as j"
      class="slider slider-ban_big"
      style="margin: 0px"
    >
      <app-small-blocks
        [blockName]="blockName"
        [slides]="slides"
        [indexValue]="i"
        [img_index]="j"
      ></app-small-blocks>
   
  </ng-template>
</ng-container>
</owl-carousel-o>
<!-- <div class="slider_nav">
  <button class="am-next">Next</button>
  <button class="am-prev">Previous</button>
</div> -->

<!-- <ngx-slick-carousel
  class="carousel"
  #slickModalTravel="slick-carousel"
  [config]="slideConfig"
  *ngIf="slides != null"
>
  <ng-container *ngFor="let slide of slides; index as i">
    <div
      ngxSlickItem
      *ngFor="let img of slides[i].images; index as j"
      class="slider slider-ban_big"
      style="margin: 0px"
    >
      <app-small-blocks
        [blockName]="blockName"
        [slides]="slides"
        [indexValue]="i"
        [img_index]="j"
      ></app-small-blocks>
    </div>
  </ng-container>
</ngx-slick-carousel> -->
