<div class="heading">
  <h2>
    <span>{{ categoryName }}</span>
  </h2>
  <hr />
</div>

<div
  class="col-md-12 col-xs-12 no-gutters tripura12_post_inner"
  *ngFor="let in of counter(no_of_posts); let i = index"
>
  <app-small-feature-block
    [slides]="slides"
    [indexValue]="i"
    [show_image]="show_image"
  ></app-small-feature-block>
</div>
