import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';


@Component({
  selector: 'app-share-post',
  // standalone:true,
  // imports: [CommonModule, ShareButtonModule, ShareIconsModule],
  templateUrl: './share-post.component.html',
  styleUrls: ['./share-post.component.css'],
})
export class SharePostComponent implements OnInit {
 
  @Input() url:string;
  constructor() { }

  public socialLinks: any = [
    'facebook',
    'twitter',
    'email',
    'linkedin',
    'pinterest',
    'reddit',
    'telegram',
    'sms',
    'copy'
  ];
  
  ngOnInit(): void {
  }

}
