import { ElementRef, Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-video-blocks',
  templateUrl: './video-blocks.component.html',
  styleUrls: ['./video-blocks.component.css'],
})
export class VideoBlocksComponent implements OnInit {
  @ViewChild('ban_vid', { static: false }) ban_vid: ElementRef;
  @ViewChild('youtubeVideo', { static: false }) youtubeVideo: ElementRef;
  @ViewChild('embededBannerImage', { static: false })
  embededBannerImage: ElementRef;

  @Input() slides;
  @Input() blockName;
  @Input() indexValue;
  videoCategory = 1;
  videoName = '';
  urlSafe: SafeResourceUrl;
  constructor(
    private myElement: ElementRef,
    public filePathsService: FilePathsService,
    private fetchPostDataService: FetchPostDataService,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.videoCategory = this.slides[this.indexValue].video_category;
    if (this.slides[this.indexValue]['videos'] != null) {
      this.videoName= this.slides[this.indexValue]['videos'][0]['video_name'];
      if(this.videoCategory==2){
        this.videoName="https://www.youtube.com/embed/"+
                      this.slides[this.indexValue]['videos'][0]['video_name']+
                      "?enablejsapi=1&version=3&playerapiid=ytplayer";
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.videoName);
      }
      
    }
  }

  onRedirecting(url: string){
    this.router.navigate([url]);
  }

  triggerVideo() {
    if (this.videoCategory == 1) {
      if (this.ban_vid.nativeElement.paused) {
        this.ban_vid.nativeElement.play();
        this.myElement.nativeElement.querySelector('.for_video').classList.add('vid_playing');
      } else {
        this.ban_vid.nativeElement.pause();
        this.myElement.nativeElement.querySelector('.for_video').classList.remove('vid_playing');
      }
    } else {
      this.embededBannerImage.nativeElement.style.display = 'none';
      this.youtubeVideo.nativeElement.style.display = 'block';
      this.youtubeVideo.nativeElement.contentWindow.postMessage(
        '{"event":"command","func":"' + 'playVideo' + '","args":""}',
        '*'
      );
      this.myElement.nativeElement.querySelector('.for_video').classList.add('vid_playing');
    }
  }

  pauseVideo() {
    if (this.videoCategory == 1) {
      if (this.ban_vid.nativeElement.paused) {
        this.ban_vid.nativeElement.play();
        this.myElement.nativeElement.querySelector('.for_video').classList.add('vid_playing');
      } else {
        this.ban_vid.nativeElement.pause();
        this.myElement.nativeElement.querySelector('.for_video').classList.remove('vid_playing');
      }
    } else {
      this.embededBannerImage.nativeElement.style.display = 'block';
      this.youtubeVideo.nativeElement.style.display = 'none';
      this.youtubeVideo.nativeElement.contentWindow.postMessage(
        '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
        '*'
      );
      this.myElement.nativeElement.querySelector('.for_video').classList.remove('vid_playing');
    }
  }
}
