import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-block-selection',
  templateUrl: './block-selection.component.html',
  styleUrls: ['./block-selection.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BlockSelectionComponent implements OnInit {

  @Input() slides;
  @Input() blockType;
  @Input() blockName;
  constructor() { }

  ngOnInit(): void {
  }

}
