<div class="heading">
  <h2><span>You may also like</span></h2>
  <hr />
</div>

<div class="row inner_tripura_featured">
  <div class="slider slider-you-may-also-like">
    <!-- <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slick_slideConfig"
      *ngIf="relatedPostData.length < 3"
    >
      <div
        ngxSlickItem
        class="small_banner_cont"
        *ngFor="let slide of relatedPostData.slice(0, 10)"
      >
        <app-small-gallery-slide [slide]="slide"></app-small-gallery-slide>
      </div>
    </ngx-slick-carousel> -->
    <owl-carousel-o [options]="slideConfig" *ngIf="relatedPostData.length < 3">
      <ng-container class="small_banner_cont" *ngFor="let slide of relatedPostData.slice(0, 10)">
        <ng-template carouselSlide>
          <div class="small_banner_cont">
            <app-small-gallery-slide [slide]="slide"></app-small-gallery-slide>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    
    <owl-carousel-o [options]="slideConfig" *ngIf="relatedPostData.length > 2">
      <ng-container *ngFor="let slide of relatedPostData.slice(0, 10)">
        <ng-template carouselSlide>
          <div class="small_banner_cont">
            <app-small-gallery-slide [slide]="slide"></app-small-gallery-slide>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
