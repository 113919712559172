import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-you-may-also-like',
  templateUrl: './you-may-also-like.component.html',
  styleUrls: ['./you-may-also-like.component.css'],
})
export class YouMayAlsoLikeComponent implements OnInit {
  @Input() relatedPostData;

  // slick_slideConfig = {
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   autoplay: true,
  //   pauseOnHover: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         infinite: true
  //       }
  //     }
  //   ]
  // };

  

  slideConfig: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      940: {
        items: 5
      },
      1024: {
        items: 5
      }
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
