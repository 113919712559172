<div class="container">
  <div class="heading">
    <h2><span>{{categoryName}}</span></h2>
    <hr />
  </div>
  <div class="row india_cont" *ngIf="slides!=null">
    <div *ngFor="let slide of slides; index as i">
        <app-india-news-block *ngIf="i<9" [slide]="slide"></app-india-news-block>
    </div>
  </div>
</div>
