<div class="row world_news world_news_dark">
  <div class="container">
    <div class="heading">
      <h2>
        <span>{{ categoryName }}</span>
      </h2>
      <hr />
    </div>
    <div class="row inner_tripura_featured_video">
      <div class="slider slider-gallery-for_world_news">
        <owl-carousel-o [options]="slideConfig">
          <ng-container *ngFor="let slide of slides.slice(0,10)">
            <ng-template carouselSlide>
              <div class="small_banner_cont">
                <app-small-gallery-slide
                  [slide]="slide"
                ></app-small-gallery-slide>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
