import { Component, OnInit } from '@angular/core';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';

@Component({
  selector: 'app-tripura-featured',
  templateUrl: './tripura-featured.component.html',
  styleUrls: ['./tripura-featured.component.css'],
})
export class TripuraFeaturedComponent implements OnInit {
  slides = null;
  loader = true;
  constructor(private fetchPostDataService: FetchPostDataService) {}

  ngOnInit(): void {
    this.fetchPostDataService.blockpostdata.subscribe((x) => {
      if (x) {
        this.getPostdata();
        this.loader = false;
      }
    });
  }

  getPostdata(data = this.fetchPostDataService.postdataValue) {
    console.log(this.slides);

    for (let i = 0; i < data.length; i++) {
      if (data[i]['block_name'] == 'Tripura Featured') {
        let posts = this.fetchPostDataService.arrayConvertion(
          data[i]['block_posts']
        );
        for (let j = 0; j < posts.length; j++) {
          if (posts[j]['images'] != null) {
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(
              posts[j]['images']
            );
          }
        }
        this.slides = posts;
      }
    }
  }

  counter(i: number) {
    if (i > this.slides.length - 2) {
      return new Array(this.slides.length - 2);
    }
    return new Array(i);
  }
}
