<div class="ban_image_wrapper for_video" *ngIf="videoCategory != 0">
  <div *ngIf="videoCategory == 1; else embededVideoLink">
    <img class="banner_img for_dummy" src="./assets/img/default_image.png" />

    <video
      id="ban_vid"
      [poster]="
        slides[indexValue].images != null
          ? filePathsService.serverImagePath +
            slides[indexValue].images[0].image_name
          : './assets/img/default_image.png'
      "
      #ban_vid
    >
      <source
        src="{{ filePathsService.serverVideoPath + videoName }}"
        type="video/mp4"
      />
      Your browser does not support HTML video.
    </video>
  </div>
  <ng-template #embededVideoLink>
    <img class="banner_img for_dummy" src="./assets/img/default_image.png" />
    <img
      #embededBannerImage
      class="banner_img"
      [src]="
        slides[indexValue].images != null
          ? filePathsService.serverImagePath +
            slides[indexValue].images[0].image_name
          : './assets/img/default_image.png'
      "
    />
    <iframe
      height="100%"
      style="display: none; width: 100%"
      #youtubeVideo
      [src]="urlSafe"
      modestbranding="0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </ng-template>
  <div class="hover_cont_outer">
    <div class="hover_cont">
      <h2 class="banner_heading">
        <a [routerLink]="'/' + slides[indexValue].slug_url">{{
          filePathsService.truncate(slides[indexValue].title, 35)
        }}</a>
      </h2>
      <span class="banner_time">
        <svg
          version="1.1"
          class="clocksvg"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 559.98 559.98"
          style="enable-background: new 0 0 559.98 559.98"
          xml:space="preserve"
        >
          <g>
            <path
              d="M279.99,0C125.601,0,0,125.601,0,279.99c0,154.39,125.601,279.99,279.99,279.99c154.39,0,279.99-125.601,279.99-279.99
                C559.98,125.601,434.38,0,279.99,0z M279.99,498.78c-120.644,0-218.79-98.146-218.79-218.79
                c0-120.638,98.146-218.79,218.79-218.79s218.79,98.152,218.79,218.79C498.78,400.634,400.634,498.78,279.99,498.78z"
            />
            <path
              d="M304.226,280.326V162.976c0-13.103-10.618-23.721-23.716-23.721c-13.102,0-23.721,10.618-23.721,23.721v124.928
                c0,0.373,0.092,0.723,0.11,1.096c-0.312,6.45,1.91,12.999,6.836,17.926l88.343,88.336c9.266,9.266,24.284,9.266,33.543,0
                c9.26-9.266,9.266-24.284,0-33.544L304.226,280.326z"
            />
          </g>
        </svg>
        <i>{{ slides[indexValue].post_date }}</i>
        <p class="ban_para">
          <img
            class="video_trigger"
            (click)="triggerVideo()"
            src="./assets/img/play.svg"
          />
          <img
            class="video_paused"
            (click)="pauseVideo()"
            src="./assets/img/pause.svg"
          />
        </p>
      </span>
    </div>
  </div>
</div>
<app-small-blocks
  *ngIf="videoCategory == 0"
  [slides]="slides"
  [blockName]="blockName"
  [indexValue]="indexValue"
></app-small-blocks>
