<div class="row social_googleads_news_cont">
  <div class="heading" style="visibility: hidden;">
    <h2><span>Stay Connected</span></h2>
    <hr />
  </div>

  <div class="row social_cont_outer">
    <div class="col-md-12" id="add-snippet" style="padding: 0px; overflow: hidden;">
      <img class="" src="./assets/img/googleads.jpg" width="100%"/>
    </div>
  </div>
</div>