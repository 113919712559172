import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FetchPostDataService } from '../services/fetch-post-data.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css'],
})
export class SearchPageComponent implements OnInit {
  search_term: string;
  slides = [];

  constructor(
    private route: ActivatedRoute,
    private fetchPostDataService: FetchPostDataService
  ) {
    this.route.params.subscribe((params: Params) => {
      this.search_term = params['term'];

      this.fetchPostDataService
      .getsearchResult(this.search_term)
      .subscribe((data) => {
        this.slides = data;
      });
    })
  }

  ngOnInit(): void {}
}
