<div class="outer_nav_cont">
  <div class="row nav_outer_for_logo">
    <div class="container">
      <div class="nav_inner">
        <a (click)="urlRouting('/')">
          <img
            class="logo logo_header"
            [src]="
              logo == ''
                ? './assets/img/logo.svg'
                : filePathsService.serverSystemImagePath + logo
            "
          />
        </a>
        <div class="social_cont">
          <div class="social_inner">
            <span class="social_nav facebook">
              <img
                src="./assets/img/facebook.svg"
                (click)="gotoNewUrl(facebook)"
              />
            </span>
            <span class="social_nav twitter" style="fill: white">
              <img
                src="./assets/img/linkedin-in-brands.svg"
                (click)="gotoNewUrl(linkedin)"
              />
            </span>
            <span class="social_nav rss">
              <img
                src="./assets/img/youtube-brands.svg"
                (click)="gotoNewUrl(youtube)"
              />
            </span>
          </div>
          <div class="input_cont">
            <input
              type="text"
              [(ngModel)]="search_term"
              placeholder="Search Here"
            />
            <button class="nav_search_button" (click)="searchResult()">
              <img src="./assets/img/search.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row nav_outer_for_nav" id="nav_outer_for_nav">
    <div class="container">
      <ul class="menucont">
        <li class="close_nav" onclick="close_nav()">&#10006;</li>
        <ng-container *ngFor="let menu of nav_data">

          <li class="li_navigation dd_tri" (click)="urlRouting(menu.href)">
            <span class="menu_letter" 
              >{{ menu.text
              }}<span *ngIf="menu.children != undefined"> &#9650; </span></span
            >
            <div class="dd_inner" *ngIf="menu.children != undefined">
              <ul>
                <ng-container *ngFor="let submenu of menu.children">
                  <li class="dd_tri" (click)="urlRouting(submenu.href)" >
                    <span class="menu_letter" 
                      >{{ submenu.text
                      }}<span *ngIf="submenu.children != undefined">
                        &#9650;
                      </span></span
                    >
                    <div class="dd_inner" *ngIf="submenu.children != undefined">
                      <ul>
                        <li *ngFor="let sub_submenu of submenu.children">
                          <span
                            class="menu_letter"
                            (click)="urlRouting(sub_submenu.href)"
                            >{{ sub_submenu.text }}</span
                          >
                        </li>
                      </ul>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </li>
        </ng-container>
      </ul>
      <span class="search_cont">
        <input
          type="text"
          [(ngModel)]="search_term"
          placeholder="Search here"
        />
        <button (click)="searchResult()">
          <svg style="height: 20px" viewBox="0 0 12 13">
            <g stroke-width="2" fill="none">
              <path d="M11.29 11.71l-4-4" />
              <circle cx="5" cy="5" r="4" />
            </g>
          </svg>
        </button>
      </span>
      <span class="hamburger_cont" onclick="mob_menu_function()">
        <label for="check">
          <input type="checkbox" id="check" />
          <span></span>
          <span></span>
          <span></span>
        </label>
      </span>
    </div>
  </div>
</div>
