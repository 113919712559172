<h4 style="margin-top: 30px;">{{post_data.videos[0].video_desc}}</h4>

<video controls *ngIf="post_data.video_category == 1" width="100%">
  <source
    src="{{
      filePathsService.serverVideoPath + post_data.videos[0].video_name
    }}"
    type="video/mp4"
  />
  Your browser does not support HTML video.
</video>
<iframe
  *ngIf="post_data.video_category == 2"
  width="100%"
  height="400px"
  [src]="post_data.videos[0].video_name"
>
</iframe>
<p>{{ urlSafe }}</p>
