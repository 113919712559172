import { Component, Input, OnInit } from '@angular/core';
// import { OwlOptions } from 'ngx-owl-carousel-o';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { OwlOptions } from 'ngx-owl-carousel-o'; 

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  @Input() display_slide = 5;

  slideConfig: OwlOptions;

  loader = true;
  slides = [];

  constructor(
    private fetchPostDataService: FetchPostDataService
  ) {}

  ngOnInit(): void {
    this.slideConfig = {
      loop: true,
      items:this.display_slide,
      autoplay: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      autoplayHoverPause: true,
      autoplaySpeed: 1000,
      navSpeed: 700,
      navText: ['', ''],
      // responsive: {
      //   0: {
      //     items: 2,
      //   },
      //   400: {
      //     items: 2,
      //   },
      //   940: {
      //     items: this.display_slide,
      //   },
      //   1024: {
      //     items: this.display_slide,
      //   },
      // },
    };

    this.fetchPostDataService.albumpostdata.subscribe((x) => {
      if (x) {
        this.getPostdata();
        this.loader = false;
      }
    });
  }

  getPostdata(data = this.fetchPostDataService.albumpostdataValue) {
    for (let i = 0; i < data.length; i++) {
      data[i]['images'] = this.fetchPostDataService.arrayConvertion(
        data[i]['images']
      );
    }
    this.slides = data;
  }
}
