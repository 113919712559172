import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-archive-list',
  templateUrl: './archive-list.component.html',
  styleUrls: ['./archive-list.component.css']
})
export class ArchiveListComponent implements OnInit {

  @Input() archive_dataset = [];
  
  constructor() { }

  ngOnInit(): void {}

  

}
