import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-small-life-style',
  templateUrl: './small-life-style.component.html',
  styleUrls: ['./small-life-style.component.css']
})
export class SmallLifeStyleComponent implements OnInit, OnChanges {

  slides=[];
  categoryName;
  @Input() category_id;
  @Input() no_of_posts=5;
  @Input() show_image = true;
  loader=true;
  constructor(private fetchPostDataService:FetchPostDataService,
              public filePathsService: FilePathsService) { }

  ngOnInit(): void {
    this.fetchPostDataService.categorypostdata.subscribe(x =>{
      if(x){
        this.getCategorydata();
        this.loader=false;
      }
    } );
  }

  ngOnChanges(){
    this.fetchPostDataService.categorypostdata.subscribe(x =>{
      if(x){
        this.getCategorydata();
        this.loader=false;
      }
    } );
  }

  getCategorydata(data=this.fetchPostDataService.categorypostdataValue){
    
    for(let i=0; i<data.length;i++){
      if(data[i]['id']==this.category_id){
        this.categoryName = data[i]['name'];
        let posts = this.fetchPostDataService.arrayConvertion(data[i]['category_posts']);
        for(let j=0;j<posts.length;j++){
          if(posts[j]['images']!=null){
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(posts[j]['images']);
          }
        }
        this.slides = posts;
        if(this.slides.length<this.no_of_posts){
          this.no_of_posts = this.slides.length;
        }
      }
    }
  }

  counter(i: number) {
    return new Array(i);
  }

}
