<div class="heading" *ngIf="!loader">
  <h2>
    <span>{{ categoryName }}</span>
  </h2>
  <hr />
</div>
<div class="slider fashions">
  <!-- Owl Slider Start -->

  <owl-carousel-o [options]="customOptions" *ngIf="slides.length > 0">
    <ng-container
      class="fashionbanner"
      *ngFor="let in of counter(sliderLength); let i = index"
      (click)="onRedirecting(slides[i].slug_url)"
    >
      <ng-template carouselSlide [id]="i">
        <img
          [src]="slides[i].images != null? filePathsService.serverImagePath + slides[i].images[0].image_name : './assets/img/default_image.png'"
        />
        <div class="hover_info_gallery">
          <p class="gallery_info">
            {{ filePathsService.truncate(slides[i].title, 90) }}
          </p>
          <span class="banner_time_life_style">
            <img src="./assets/img/clock_white.svg" />
            <i>{{ slides[i].post_date | date : mediumDate }}</i>
          </span>
        </div>
       
      </ng-template>
    </ng-container>
  </owl-carousel-o>

  <!-- Owl Slider End -->

  <!-- <ngx-slick-carousel
    class="carousel"
    #slickModalTravel="slick-carousel"
    [config]="slideConfig"
    *ngIf="slides.length > 0"
  >
    <div
      ngxSlickItem
      class="fashionbanner"
      *ngFor="let in of counter(sliderLength); let i = index"
      (click)="onRedirecting(slides[i].slug_url)"
    >
      <img
        [src]="
          slides[i].images != null
            ? filePathsService.serverImagePath + slides[i].images[0].image_name
            : './assets/img/default_image.png'
        "
      />
      <div class="hover_info_gallery">
        <p class="gallery_info">
          {{ filePathsService.truncate(slides[i].title, 90) }}
        </p>
        <span class="banner_time">
          <img src="./assets/img/clock_white.svg" />
          <i>{{ slides[i].post_date | date : mediumDate }}</i>
        </span>
      </div>
    </div>
  </ngx-slick-carousel> -->
</div>
