<div class="row outerunderbanner_row">
  <div class="container">
    <div class="heading">
      <h2>
        <span>Archive Details</span>
      </h2>
      <hr />
    </div>
    <div
      class="col-md-4 col-xs-6 no-gutters tripura12_post_inner archive-list"
      *ngFor="let slide of slides; let i = index"
    >
      <app-small-feature-block
        [slides]="slides"
        [indexValue]="i"
        [show_image]="false"
      ></app-small-feature-block>
    </div>
  </div>
</div>
