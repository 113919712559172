import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css'],
})

export class ImageSliderComponent implements OnInit {
  @Input() image_data;
  // @ViewChild('owlElement') owlElement: OwlCarousel;

  // refresh() {
  //   this.owlElement.refresh()
  // }
  // @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  
  customOptions: OwlOptions ;

  // slideConfig = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   autoplay: true,
  //   pauseOnHover: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  // };
  constructor(public filePathsService: FilePathsService) {}

  ngOnInit(): void {
   this.customOptions ={
    loop: this.image_data.length >1 ? true:false,
    mouseDrag: this.image_data.length >1 ? true:false,
    pullDrag: false,
    autoplay:this.image_data.length >1 ? true:false,
    dots: false,
    navSpeed: 700,
    items: 1,
  }
    // if (!this.slickModal.initialized) {
    //   this.slickModal.initSlick();
    // } else if (this.slickModal.initialized) {
    //   this.slickModal.unslick();
    // }
  }
}
