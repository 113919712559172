import { Component, Input, OnInit } from '@angular/core';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-small-feature-block',
  templateUrl: './small-feature-block.component.html',
  styleUrls: ['./small-feature-block.component.css'],
})
export class SmallFeatureBlockComponent implements OnInit {
  @Input() indexValue: string;
  @Input() show_image = true;
  @Input() slides = [];

  constructor(public filePathsService: FilePathsService) {}

  ngOnInit(): void {}
}
