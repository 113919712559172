<div class="container">
  <div class="container--tabs">
    <section class="row">
      <ul class="nav nav-tabs">
        <li class="active" [ngClass]="{ active: setValue === 'post' }">
          <a (click)="setValue = 'post'">Posts</a>
        </li>
        <li class="" [ngClass]="{ active: setValue === 'album' }">
          <a (click)="setValue = 'album'">Albums</a>
        </li>
        <li class="" [ngClass]="{ active: setValue === 'video' }">
          <a (click)="setValue = 'video'">Videos</a>
        </li>
      </ul>
      <div class="tab-content">
        <div
          id="tab-1"
          class="row tab-pane active"
          [ngClass]="{ active: setValue === 'post' }"
        >
          <app-archive-list [archive_dataset]="postData"></app-archive-list>
        </div>
        <div
          id="tab-2"
          class="row tab-pane"
          [ngClass]="{ active: setValue === 'album' }"
        >
          <app-archive-list [archive_dataset]="albumData"></app-archive-list>
        </div>
        <div
          id="tab-3"
          class="row tab-pane"
          [ngClass]="{ active: setValue === 'video' }"
        >
          <app-archive-list [archive_dataset]="videoData"></app-archive-list>
        </div>
      </div>
    </section>
  </div>
</div>
