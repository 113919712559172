import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilePathsService {
  serverImagePath: string = 'assets/upload/images/';
  serverSystemImagePath: string = 'assets/upload/setting_img/';
  serverVideoPath: string = 'assets/upload/videos/'
  // serverPath = "http://localhost/tripura-backend/portal/api/";
  serverPath = "api/";
  initialhref = '';

  /************* PROD **************/

  // serverImagePath: string = 'http://companyprojects.websofttechs.com/tripura_engine/assets/upload/images/';
  // serverSystemImagePath: string = 'http://companyprojects.websofttechs.com/tripura_engine/assets/upload/setting_img/';
  // serverVideoPath: string = 'http://companyprojects.websofttechs.com/tripura_engine/assets/upload/videos/'
  // serverPath = "http://companyprojects.websofttechs.com/tripura_engine/api/";
  baseUrl = "";


  constructor() { }

  truncate(str, no_words) {
    // return str.split(" ").splice(0,no_words).join(" ");
    if(str.length <= no_words){
      return str;
    }
    else{
      return str.substring(0, no_words-1)+'...';
    }
  }

  youtube_parser(url){
    if (url!=undefined) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      
      var match = url?url.match(regExp):'';
      return (match&&match[7].length==11)? match[7] : false;
      // return 'https://www.youtube.com/watch?v=VF-IBCNvEcs'
    } else {
      return false;
    }
  }

  set_paths(url){
    this.serverPath = url + this.serverPath;
    this.serverImagePath = url + this.serverImagePath;
    this.serverSystemImagePath = url + this.serverSystemImagePath;
    this.serverVideoPath = url + this.serverVideoPath;
  }
}
