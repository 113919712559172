<span class="tab_inner" *ngIf="archive_dataset != null">
  <div class="archive_outer" *ngFor="let archive of archive_dataset">
    <app-category-archive
      *ngIf="archive.posts.length > 0"
      [categoryName]="archive['name']"
      [categorySlug]="archive['slug_url']"
      [dataList]="archive['posts']"
    ></app-category-archive>
  </div>
</span>
