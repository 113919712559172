<div class="ticker_contaner">
  <div class="container ticker_contaner_inner">
    <div class="col-md-2 col-sm-2 ticker_label_cont no-gutters">
      <span class="ticker_label"><span>Breaking News</span></span>
    </div>
    <div class="col-md-9 col-sm-10 ticker_message_cont">
      <owl-carousel-o [options]="customOptions" class="carousel">

        <ng-container *ngFor="let slide of slides">
          <ng-template class="item" carouselSlide [id]="slide.id" >
            <div (click)="redirectPage(slide.slug_url)">
              <span class="date text-danger">{{ slide.post_date | date:'mediumDate' }} </span>
              <span class="head_lines" >{{ slide.title }}</span> 
            </div>
            <!-- <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title"> -->
          </ng-template>
        </ng-container>
    
      </owl-carousel-o>
    </div>
    <!-- <div class="col-md-9 ticker_message_cont">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div
          ngxSlickItem
          *ngFor="let slide of slides"
          class="slide item"
          (click)="redirectPage(slide.slug_url)"
        >
          <span class="date">{{ slide.post_date | date:'mediumDate' }} </span>{{ slide.title }}
        </div>
      </ngx-slick-carousel>
    </div> -->
    <!-- <div class="slider-button" *ngIf="!loader && slides != null">
      <a (click)="prev()" style="padding-right: 5px; padding-left: 10px"
        ><img src="./assets/img/arrow-left-solid.svg" alt="" height="25px"
      /></a>
      <a (click)="next()" style="padding-left: 5px"
        ><img src="./assets/img/arrow-right-solid.svg" alt="" height="25px"
      /></a>
    </div> -->
  </div>
</div>
