import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-slider-block',
  templateUrl: './slider-block.component.html',
  styleUrls: ['./slider-block.component.css'],
})
export class SliderBlockComponent implements OnInit {
  @Input() blockName;
  @Input() slides;
  count = 0;
  // slideConfig = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   autoplay: true,
  //   pauseOnHover: true,
  //   autoplaySpeed: 5000,
  //   speed: 1500,
  // };

  constructor() {}

  // owl
  customOptions: OwlOptions = {
    loop: true,
    items:1,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 1500,
    // navText: ['<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right"></i>' ],
    autoplay:true,
    autoplaySpeed:2000,
  }


  ngOnInit(): void {
    for (let i = 0; i < this.slides.length; i++) {
      this.count += this.slides[i].images.length;
    }
  }
}
