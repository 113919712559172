import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FetchPostDataService } from '../services/fetch-post-data.service';

@Component({
  selector: 'app-archive-detail',
  templateUrl: './archive-detail.component.html',
  styleUrls: ['./archive-detail.component.css'],
})
export class ArchiveDetailComponent implements OnInit {

  postType: string;
  categorySlug: string;
  month: number;
  year: number;
  slides = [];
  constructor(
    private route: ActivatedRoute,
    private fetchPostDataService: FetchPostDataService
  ) {
    this.route.params.subscribe((params: Params) => {
      this.postType = params['post_type'];
      this.categorySlug = params['category_slug'];
      this.month = params['month'];
      this.year = params['year'];

      this.fetchPostDataService
      .getArchiveDataList(this.postType, this.categorySlug, this.month, this.year)
      .subscribe((data) => {
        this.slides = data;
      });
    });
  }

  ngOnInit(): void {}
}
