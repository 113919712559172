<div class="heading">
  <h2>
    <span>{{ categoryName }}</span>
  </h2>
  <hr />
</div>
<div class="archive_cont">
  <ul>
    <a
      [routerLink]="
        data.post_type + '/' + categorySlug + '/' + data.month + '/' + data.year
      "
      *ngFor="let data of dataList"
    >
      <li>
        {{ month[data.month - 1] }}-{{ data.year }} ({{ data.no_of_posts }})
      </li>
    </a>
  </ul>
</div>
