<div class="row outerunderbanner_row">
  <div class="container">
    <div class="row innerunderbanner_row">
      <div class="col-md-8 tripura_cont no-gutters">
        <app-post-details [post_data]="post_data"></app-post-details>
        <app-share-post
          [url]="filePathsService.baseUrl + slug_url"
        ></app-share-post>
        <div class="row gallery_start">
          <app-you-may-also-like
            [relatedPostData]="relatedPostData"
          ></app-you-may-also-like>
        </div>
      </div>
      <div class="col-md-4 social_height">
        <div
          class="
            col-md-12
            no-gutters
            sports_outer
            for_secpndery_page_side_bar
            static_sidebar_cont
          "
        >
          <app-small-life-style [category_id]="11"></app-small-life-style>
        </div>
        <app-stay-connected></app-stay-connected>
      </div>
    </div>
  </div>
</div>
