<div class="col-md-4 col-xs-6 india_cont" (click)="onRedirecting(slide.slug_url)">
  <img
    [src]="
      slide.images != null
        ? filePathsService.serverImagePath + slide.images[0].image_name
        : './assets/img/default_image.png'
    "
  />
  <div class="india_info">
    <p>{{ filePathsService.truncate(slide.title, 60) }}</p>
    <span class="banner_time">
      <img class="clock" src="./assets/img/clock.svg" /><i>{{
        slide.post_date | date:'mediumDate'
      }}</i>
    </span>
  </div>
</div>
