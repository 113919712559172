import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArchiveDetailComponent } from './archive-detail/archive-detail.component';
import { ArchivePageComponent } from './archive-page/archive-page.component';
import { CategoryPageComponent } from './category-page/category-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { SinglePostPageComponent } from './single-post-page/single-post-page.component';


const routes: Routes = [{path: '', component: HomePageComponent, pathMatch: 'full'},
                        {path: 'search/:term', component: SearchPageComponent},
                        {path: 'archive', component: ArchivePageComponent, pathMatch: 'full'},
                        {path: 'archive/:post_type/:category_slug/:month/:year', component: ArchiveDetailComponent},
                        {path: 'category/:slug_url', component: CategoryPageComponent},
                        {path: ':slug_url', component: SinglePostPageComponent}
                        ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }