import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-show-video',
  templateUrl: './show-video.component.html',
  styleUrls: ['./show-video.component.css']
})
export class ShowVideoComponent implements OnInit {

  @Input() post_data;
  videoName: string = '';
  urlSafe: SafeResourceUrl;
  constructor(private route: ActivatedRoute, public filePathsService: FilePathsService,
    public sanitizer: DomSanitizer) {
      this.route.params.subscribe((params: Params)=>{
        this.ngOnInit();
      }) ;
    }

  ngOnInit(): void {
    if(this.post_data.video_category==2){
      this.videoName = this.filePathsService.youtube_parser(this.post_data.videos[0].video_name)||'';
      this.videoName="https://www.youtube.com/embed/"+
                    this.videoName+
                    "?enablejsapi=1&version=3&playerapiid=ytplayer";
      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.videoName);
      
    }
  }

}
