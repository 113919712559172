import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-archive',
  templateUrl: './category-archive.component.html',
  styleUrls: ['./category-archive.component.css']
})
export class CategoryArchiveComponent implements OnInit {

  @Input() categoryName;
  @Input() categorySlug;
  @Input() dataList = [];
  month= ["January","February","March","April","May","June","July",
            "August","September","October","November","December"];
  constructor() { }

  ngOnInit(): void {
  }

}
