import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FetchPostDataService } from '../services/fetch-post-data.service';

@Component({
  selector: 'app-archive-page',
  templateUrl: './archive-page.component.html',
  styleUrls: ['./archive-page.component.css'],
})
export class ArchivePageComponent implements OnInit {
  setValue: string = 'post';
  archivedataset = [];
  postData = [];
  albumData = [];
  videoData = [];
  constructor(private fetchPostDataService: FetchPostDataService,
              private route: ActivatedRoute) {}

  ngOnInit(): void {

    this.fetchPostDataService.archivedata.subscribe((x) => {
      if (x) {
        this.getPostdata();
      }
    });
  }

  getPostdata(data = this.fetchPostDataService.archivedataValue) {
    this.postData = JSON.parse(JSON.stringify(data));
    this.videoData = JSON.parse(JSON.stringify(data));
    this.albumData = JSON.parse(JSON.stringify(data));
    for (let i = 0; i < data.length; i++) {
      data[i]['posts'] = this.fetchPostDataService.arrayConvertion(
        data[i]['posts']
      );
      let posts = data[i]['posts'];

      let newPost = [];
      let newVideo = [];
      let newAlbum = [];
      for (let j = 0; j < posts.length; j++) {
        if (posts[j]['post_type'] == 'post') {
          newPost.push(posts[j]);
        }
        if (posts[j]['post_type'] == 'album') {
          newAlbum.push(posts[j]);
        }
        if (posts[j]['post_type'] == 'video') {
          newVideo.push(posts[j]);
        }
      }
      newPost = this.sortYear(newPost);
      newAlbum = this.sortYear(newAlbum);
      newVideo = this.sortYear(newVideo);
      
      this.postData[i].posts = newPost;
      this.videoData[i]['posts'] = newVideo;
      this.albumData[i]['posts'] = newAlbum;
    }
  }
  sortYear(arr) {
    let len = arr.length;
    for (let i = len - 1; i >= 0; i--) {
      for (let j = 1; j <= i; j++) {
        if ((arr[j - 1].year*10)-arr[j - 1].month < (arr[j].year*10)-arr[j].month) {
          let temp = arr[j - 1];
          arr[j - 1] = arr[j];
          arr[j] = temp;
        }
      }
    }
    return arr;
  }
}
