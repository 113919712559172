import { Component, OnInit } from '@angular/core';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-india-news',
  templateUrl: './india-news.component.html',
  styleUrls: ['./india-news.component.css']
})
export class IndiaNewsComponent implements OnInit {

  slides = [];
  categoryName;
  loader=true;
  constructor(private fetchPostDataService:FetchPostDataService,
              public filePathsService: FilePathsService) { }

  ngOnInit(): void {
    this.fetchPostDataService.categorypostdata.subscribe(x =>{
      if(x){
        this.getCategorydata();
        this.loader=false;
      }
    } );
  }

  getCategorydata(data=this.fetchPostDataService.categorypostdataValue){
    
    for(let i=0; i<data.length;i++){
      if(data[i]['id']=='9'){
        this.categoryName = data[i]['name'];
        let posts = this.fetchPostDataService.arrayConvertion(data[i]['category_posts']);
        for(let j=0;j<posts.length;j++){
          if(posts[j]['images']!=null){
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(posts[j]['images']);
          }
        }
        this.slides = posts;
      }
    }
  }

}
