<!-- <ngx-slick-carousel
  class="carousel"
  #slickModal="slick-carousel"
  [config]="slideConfig"
>
  <div ngxSlickItem *ngFor="let image of image_data; index as i">
    <img [src]="filePathsService.serverImagePath + image.image_name" />
    <h4 class="text-center" style="margin-top: -20px">
      {{ image.image_caption }}
    </h4>
  </div>
</ngx-slick-carousel> -->
<!-- <h1> {{image_data|json}} </h1> -->
<owl-carousel-o [options]="customOptions" >
  <ng-container *ngFor="let image of image_data">
    <ng-template carouselSlide [id]="image.id">
      <img [src]="filePathsService.serverImagePath + image.image_name">
      <h4 class="text-center" style="margin-top: -20px">
        {{ image.image_caption }}
      </h4>
    </ng-template>
  </ng-container>

</owl-carousel-o>

<!-- <owl-carousel-o [options]="customOptions" *ngIf="image_data[0]">

  <ng-container *ngFor="let image of image_data[0]; let i = index">
    <ng-template carouselSlide [id]="i">

      <img [src]="filePathsService.serverImagePath + image.image_name" />
      <h4 class="text-center" style="margin-top: -20px">
        {{ image.image_caption }}
      </h4>
    </ng-template>
  </ng-container>

</owl-carousel-o> -->