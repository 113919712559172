import { Component, OnInit } from '@angular/core';
import { FetchPostDataService } from './services/fetch-post-data.service';
import { FilePathsService } from './services/file-paths.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  imagePost = {
    count: '10',
    order: 'ASC',
    pageno: '1',
    fields: 'less',
    images: '1',
    videos: '0',
    post_type: 'album',
  };
  videoPost = {
    count: '10',
    order: 'ASC',
    pageno: '1',
    fields: 'less',
    images: '1',
    videos: '1',
    post_type: 'video',
  };

  load_timer = false;
  
  initialHref = window.location.href;

  constructor(
    private fetchPostDataService: FetchPostDataService,
    private filePathsService: FilePathsService
  ) {
    //! enable the bellow code for cordova
    // this.filePathsService.initialhref = this.initialHref;
  }

  ngOnInit(): void {
    if ('server_path' in localStorage && 'base_path' in localStorage) {
      this.filePathsService.set_paths(localStorage.getItem('server_path'));
      this.filePathsService.baseUrl = localStorage.getItem('base_path');
      this.calling_API();
    } 
    else {
      this.fetchPostDataService.getServerPath().subscribe((data) => {
        localStorage.setItem('server_path', data.backend_url);
        localStorage.setItem('base_path', data.frontend_url);
        window.location.assign(this.initialHref);
      });
    }

    setTimeout(() => {
      this.load_timer = true;
    }, 4000);
  }

  calling_API() {

    this.fetchPostDataService
      .getBlockWithPost()
      .subscribe((data) => {
        if(data.server_status){
          this.fetchPostDataService.blockpostdataSubject.next(data.data);
        }
        else{
          localStorage.removeItem('server_path');
          localStorage.removeItem('base_path');
          window.location.assign(this.initialHref);
        }
      },
      (error) => {
        localStorage.removeItem('server_path');
        localStorage.removeItem('base_path');
        window.location.assign(this.initialHref);
      }
    );

    this.fetchPostDataService
      .getCategoryWithPost()
      .subscribe((data) => {
        this.fetchPostDataService.categorypostdataSubject.next(data);
    });

    this.fetchPostDataService
      .getPostDataWithImage(this.imagePost)
      .subscribe((data) => {
        this.fetchPostDataService.albumpostdataSubject.next(data);
      });

    this.fetchPostDataService
      .getPostDataWithImage(this.videoPost)
      .subscribe((data) => {
        this.fetchPostDataService.videopostdataSubject.next(data);
      });

    this.fetchPostDataService
      .getArchiveData()
      .subscribe((data) => {
        this.fetchPostDataService.archivedataSubject.next(data);
    });
  }
}
