import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilePathsService } from './file-paths.service';

@Injectable({
  providedIn: 'root',
})
export class FetchPostDataService {
  public blockpostdataSubject: BehaviorSubject<any>;
  public blockpostdata: Observable<any>;
  public defaultBlockPostData: any;

  public categorypostdataSubject: BehaviorSubject<any>;
  public categorypostdata: Observable<any>;
  public defaultCategoryPostData: any;

  public albumpostdataSubject: BehaviorSubject<any>;
  public albumpostdata: Observable<any>;
  public defaultAlbumPostData: any;

  public videopostdataSubject: BehaviorSubject<any>;
  public videopostdata: Observable<any>;
  public defaultVideoPostData: any;

  public archivedataSubject: BehaviorSubject<any>;
  public archivedata: Observable<any>;
  public defaultArchiveData: any;

  constructor(
    private http: HttpClient,
    private filePathsService: FilePathsService
  ) {
    this.blockpostdataSubject = new BehaviorSubject<any>(
      this.defaultBlockPostData
    );
    this.blockpostdata = this.blockpostdataSubject.asObservable();

    this.categorypostdataSubject = new BehaviorSubject<any>(
      this.defaultCategoryPostData
    );
    this.categorypostdata = this.categorypostdataSubject.asObservable();

    this.albumpostdataSubject = new BehaviorSubject<any>(
      this.defaultAlbumPostData
    );
    this.albumpostdata = this.albumpostdataSubject.asObservable();

    this.videopostdataSubject = new BehaviorSubject<any>(
      this.defaultVideoPostData
    );
    this.videopostdata = this.videopostdataSubject.asObservable();

    this.archivedataSubject = new BehaviorSubject<any>(this.defaultArchiveData);
    this.archivedata = this.archivedataSubject.asObservable();
  }

  public get postdataValue(): any {
    return this.blockpostdataSubject.value;
  }

  public get categorypostdataValue(): any {
    return this.categorypostdataSubject.value;
  }

  public get albumpostdataValue(): any {
    return this.albumpostdataSubject.value;
  }

  public get videopostdataValue(): any {
    return this.videopostdataSubject.value;
  }

  public get archivedataValue(): any {
    return this.archivedataSubject.value;
  }

  arrayConvertion(dataset) {
    try {
      let dataset_parsed = JSON.parse(dataset);
      for (let i = 0; i < dataset_parsed.length; i++) {
        try {
            dataset_parsed[i] = JSON.parse(dataset_parsed[i]);
        } catch (e) {
            dataset_parsed[i] = dataset_parsed[i];
        }
        
      }
      return dataset_parsed;
    } catch (e) {
      return dataset;
    }
  }

  getPostDataWithImage(data): Observable<any> {
    return this.http.post(this.filePathsService.serverPath + 'getPosts', data);
  }

  getBlockWithPost(): Observable<any> {
    return this.http.get(this.filePathsService.serverPath + 'getBlockWithPost');
  }

  getCategoryWithPost(): Observable<any> {
    return this.http.get(
      this.filePathsService.serverPath + 'getCategoryWithPost'
    );
  }

  getPostBySlug(slug_url): Observable<any> {
    return this.http.get(
      this.filePathsService.serverPath + 'getPostBySlug/' + slug_url
    );
  }

  getArchiveData(): Observable<any> {
    return this.http.get(this.filePathsService.serverPath + 'getArchive');
  }

  getArchiveDataList(postType, categorySlug, month, year): Observable<any> {
    return this.http.get(
      this.filePathsService.serverPath +
        'getArchiveDetails' +
        '/' +
        postType +
        '/' +
        categorySlug +
        '/' +
        month +
        '/' +
        year
    );
  }

  getCategoryData(slug_url): Observable<any> {
    return this.http.get(this.filePathsService.serverPath + 'getCategories'+'/'+ slug_url);
  }

  getNavMenu(): Observable<any> {
    return this.http.get(this.filePathsService.serverPath + 'getNavMenu');
  }

  getsearchResult(search_term): Observable<any> {
    return this.http.get(this.filePathsService.serverPath + 'searchPost/' + search_term);
  }

  getServerPath(): Observable<any>{
    // return this.http.get("https://www.livelikefire2021.com");
    return this.http.get("https://tripura.websofttechs.com/portal/api/test");

    // return this.http.get("http://localhost/tripura-backend/portal/api/test");
  }
}

    
