<div class="heading secondery" *ngIf="post_data">
  <h2>
    <span>  {{ post_data.title }} {{ post_data.writer }}</span>
  </h2>
  <span class="secondery_datetime" style="color: #636570;">
    {{ post_data.writer }}&nbsp;&nbsp;
    <img src="./assets/img/clock.svg"/>
    {{ post_data.post_date | date: mediumDate }}
  </span>
  <hr />
</div>
<div class="row secondery_banner_and_text_cont" *ngIf="post_data">
  <!-- {{post_data.images|json}} -->
  <app-image-slider
    *ngIf="post_data.post_type != 'video'"
    [image_data]="post_data.images"
  ></app-image-slider>
  <div class="secendery_parageaph_cont">
    <div class="quote">
      <img src="./assets/img/quote.svg" />
      {{ post_data.intro }}
    </div>
    <div
      class="main_paragraph"
      *ngIf="post_data.post_type != 'video'"
      [innerHTML]="post_data.post_body"
    ></div>
  </div>
  <app-show-video
    *ngIf="post_data.post_type == 'video' || post_data.video_category != 0"
    [post_data]="post_data"
  ></app-show-video>
</div>
