import { Component, OnInit } from '@angular/core';
import { FetchPostDataService } from '../services/fetch-post-data.service';
import { MetaService } from '../services/meta-service.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  constructor(
    private meatService: MetaService,
    private fetchPostDataService: FetchPostDataService
  ) {
    this.meatService.updateTitle();

    this.fetchPostDataService.getNavMenu().subscribe((data) => {
      document.querySelector('#add-snippet').innerHTML = data.add_snippet;
    });
  }

  ngOnInit(): void {}
}
