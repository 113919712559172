
<div class="row gallery_start">
  <div class="heading">
    <h2>
      <span>{{ headingName }}</span>
    </h2>
    <hr />
  </div>

  <div class="row inner_tripura_featured">
    <div class="slider slider-gallery">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slick_slideConfig"
        *ngIf="slides.length >2"
      >
        <div
          ngxSlickItem
          class="small_banner_cont"
          *ngFor="let slide of slides.slice(0, 10)"
        >
          <app-small-gallery-slide [slide]="slide"></app-small-gallery-slide>
        </div>
      </ngx-slick-carousel>

      <owl-carousel-o [options]="slideConfig" *ngIf="slides.length < 3">
        <ng-container *ngFor="let slide of slides.slice(0, 10)">
          <ng-template carouselSlide>
            <div class="small_banner_cont">
              <app-small-gallery-slide
                [slide]="slide"
              ></app-small-gallery-slide>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
