import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { FilePathsService } from 'src/app/services/file-paths.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-lifestyle',
  templateUrl: './lifestyle.component.html',
  styleUrls: ['./lifestyle.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LifestyleComponent implements OnInit {
  slides = [];
  categoryName;
  sliderLength = 5;

  customOptions: OwlOptions = {
    loop: true,
    items:1,
    dots: true,
    autoplay:true,
    autoplayHoverPause:true,
    autoplaySpeed:2000,
    navSpeed: 1500,
    navText: ['', ''],
    
  }

  // slideConfig = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   dots: true,
  //   autoplay: true,
  //   pauseOnHover: true,
  //   autoplaySpeed: 2000,
  //   speed: 1500,
  // };

  loader = true;
  constructor(
    private fetchPostDataService: FetchPostDataService,
    public filePathsService: FilePathsService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.fetchPostDataService.categorypostdata.subscribe((x) => {
      if (x) {
        this.getCategorydata();
        this.loader = false;
      }
    });
  }

  getCategorydata(data = this.fetchPostDataService.categorypostdataValue) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]['id'] == '7') {
        this.categoryName = data[i]['name'];

        let posts = this.fetchPostDataService.arrayConvertion(
          data[i]['category_posts']
        );
        for (let j = 0; j < posts.length; j++) {
          if (posts[j]['images'] != null) {
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(
              posts[j]['images']
            );
          }
        }
        this.slides = posts;
        if (this.slides.length < this.sliderLength) {
          this.sliderLength = this.slides.length;
        }
      }
    }
  }

  counter(i: number) {
    return new Array(i);
  }

  onRedirecting(url: string) {
    this.router.navigate([url]);
  }
}
