import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FetchPostDataService } from '../services/fetch-post-data.service';
import { FilePathsService } from '../services/file-paths.service';
import { MetaService } from '../services/meta-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  nav_data: any;
  search_term: string;
  logo: string;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  facebook: string;
  linkedin: string;
  youtube: string;

  constructor(
    private router: Router,
    private fetchPostDataService: FetchPostDataService,
    public filePathsService: FilePathsService,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.fetchPostDataService.getNavMenu().subscribe((data) => {
      this.nav_data = JSON.parse(data.nav_menu);
      this.logo = data.logo;
      this.facebook = data.facebook;
      this.linkedin = data.linkedin;
      this.youtube = data.youtube;
      
      if(data.favicon!=null){
        this.favIcon.href = this.filePathsService.serverSystemImagePath + data.favicon;
      }

      if(data.default_title!=''){
        this.metaService.defaultMeta = data.default_title;
      }

      if(data.copyright_content!=null){
        document.querySelector('footer')
      .querySelector('.copyright').innerHTML = data.copyright_content;
      }

      document.querySelector('#add-snippet').innerHTML = data.add_snippet;
    });
    
  }

  close_nav() {
    document.querySelector('.menucont').classList.remove('show_menu');
  }

  onRedirecting(url: string) {
    this.router.navigate([url]);
    this.close_nav();
  }

  gotoUrl(url: string) {
    window.location.href = url;
  }

  gotoNewUrl(url: string) {
    window.open(url,'_blank');
  }

  urlRouting(url){
    if(url!=""){
      if(url=="/"){
        window.location.assign(this.filePathsService.initialhref);
      }
      else if(url.charAt(0)=="/"){
        this.onRedirecting(url);
      }
      else{
        this.gotoUrl(url);
      }
    }
  }

  searchResult(){
    if(this.search_term!=''){
      this.router.navigate(['search', this.search_term]);
    }
  }
  
}
