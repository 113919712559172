<div class="row gallery_start">
  <div class="heading">
    <h2><span>Gallery</span></h2>
    <hr />
  </div>

  <!-- gallery-->
  <div class="row inner_tripura_featured">
    <div class="slide slider-gallery">
      <owl-carousel-o [options]="slideConfig">
        <ng-container *ngFor="let slide of slides.slice(0,10); let i= index">
          <ng-template carouselSlide [id]="i" >
            <div class="small_banner_cont">
              <app-small-gallery-slide
                [slide]="slide"
              ></app-small-gallery-slide>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    
  </div>
  <!-- gallery end-->
</div>
