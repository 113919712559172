<div class="row fashion_lifestyle_sportscont">
  <div class="col-md-4 fashion_outer no-gutters">
    <app-lifestyle></app-lifestyle>
  </div>
  <app-post-details></app-post-details>
  <div class="col-md-4 sports_outer">
    <app-small-life-style [category_id]="3"></app-small-life-style>
  </div>
  <div class="col-md-4 business_outer">
    <app-small-life-style [category_id]="8"></app-small-life-style>
  </div>
</div>
