import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BreakingNewsComponent } from './breaking-news/breaking-news.component';

// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HomePageComponent } from './home-page/home-page.component';
import { TopBannerComponent } from './home-page/top-banner/top-banner.component';
import { SmallBlocksComponent } from './home-page/top-banner/small-blocks/small-blocks.component';
import { VideoBlocksComponent } from './home-page/top-banner/video-blocks/video-blocks.component';


import { HttpClientModule } from '@angular/common/http';
import {FetchPostDataService} from './services/fetch-post-data.service';
import {FilePathsService} from './services/file-paths.service';
import { TripuraFeaturedComponent } from './home-page/tripura-featured/tripura-featured.component';
import { SmallFeatureBlockComponent } from './home-page/tripura-featured/small-feature-block/small-feature-block.component';
import { GalleryComponent } from './home-page/gallery/gallery.component';
import { FashionLifestyleSportsComponent } from './home-page/fashion-lifestyle-sports/fashion-lifestyle-sports.component';
import { LifestyleComponent } from './home-page/fashion-lifestyle-sports/lifestyle/lifestyle.component';
import { FeaturedVideoComponent } from './home-page/featured-video/featured-video.component';
import { IndiaNewsComponent } from './home-page/india-news/india-news.component';
import { IndiaNewsBlockComponent } from './home-page/india-news/india-news-block/india-news-block.component';
import { SmallGallerySlideComponent } from './home-page/gallery/small-gallery-slide/small-gallery-slide.component';
import { WorldNewsComponent } from './home-page/world-news/world-news.component';
import { SliderBlockComponent } from './home-page/top-banner/slider-block/slider-block.component';
import { BlockSelectionComponent } from './home-page/top-banner/block-selection/block-selection.component';
import { SinglePostPageComponent } from './single-post-page/single-post-page.component';

import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { PostDetailsComponent } from './single-post-page/post-details/post-details.component';
import { StayConnectedComponent } from './home-page/tripura-featured/stay-connected/stay-connected.component';
import { YouMayAlsoLikeComponent } from './single-post-page/you-may-also-like/you-may-also-like.component';
import { ShowVideoComponent } from './single-post-page/post-details/show-video/show-video.component';
import { ImageSliderComponent } from './single-post-page/post-details/image-slider/image-slider.component';
import { SmallLifeStyleComponent } from './home-page/fashion-lifestyle-sports/small-life-style/small-life-style.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { CategoryPageComponent } from './category-page/category-page.component';
import { ShowCategoryListComponent } from './category-page/show-category-list/show-category-list.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SharePostComponent } from './single-post-page/share-post/share-post.component';
import { ArchivePageComponent } from './archive-page/archive-page.component';
import { ArchiveListComponent } from './archive-page/archive-list/archive-list.component';
import { CategoryArchiveComponent } from './archive-page/archive-list/category-archive/category-archive.component';
import { ArchiveDetailComponent } from './archive-detail/archive-detail.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { CategorySliderComponent } from './category-page/category-slider/category-slider.component';
import { LazyImgDirective } from './lazy-img.directive';
import { ArticleComponent } from './home-page/article/article.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BreakingNewsComponent,
    HomePageComponent,
    TopBannerComponent,
    SmallBlocksComponent,
    VideoBlocksComponent,
    TripuraFeaturedComponent,
    SmallFeatureBlockComponent,
    GalleryComponent,
    FashionLifestyleSportsComponent,
    LifestyleComponent,
    FeaturedVideoComponent,
    IndiaNewsComponent,
    IndiaNewsBlockComponent,
    SmallGallerySlideComponent,
    WorldNewsComponent,
    SliderBlockComponent,
    BlockSelectionComponent,
    SinglePostPageComponent,
    FooterComponent,
    PostDetailsComponent,
    StayConnectedComponent,
    YouMayAlsoLikeComponent,
    ShowVideoComponent,
    ImageSliderComponent,
    SmallLifeStyleComponent,
    PreloaderComponent,
    CategoryPageComponent,
    ShowCategoryListComponent,
    SharePostComponent,
    ArchivePageComponent,
    ArchiveListComponent,
    CategoryArchiveComponent,
    ArchiveDetailComponent,
    SearchPageComponent,
    CategorySliderComponent,
    LazyImgDirective,
    ArticleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
   /* @ts-ignore */
    CarouselModule,
  ],
  providers: [
    FetchPostDataService,
    FilePathsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
