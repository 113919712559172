<div class="row share_this no-gutters">
  Share this on
  <!-- <share-button></share-button> -->
  <share-buttons
  [theme]="'circles-dark'"
  [include]="socialLinks"
  [show]="3"
  [url]="
    'https://www.acknitindia.com/newweb/p/' 
  "
  [autoSetMeta]="false"
  ></share-buttons>
  <!-- <share-buttons 
  theme="circles-dark"
  [include]="['copy', 'facebook', 'email', 'messenger', 'mix', 'line', 'linkedin', 'pinterest', 'print', 'reddit', 'sms', 'telegram', 'tumblr', 'twitter', 'viber', 'vk', 'xing', 'whatsapp']"
  [showIcon]="true"
  [showText]="false"
  url="https://www.itsolutionstuff.com/post/whats-new-in-laravel-11-new-features-and-latest-updatesexample.html"
  description="Angular 17 Share Buttons"
  twitterAccount="HardikSavani19"
  class="pt-5">
</share-buttons> -->
</div>
