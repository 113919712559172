import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-category-slider',
  templateUrl: './category-slider.component.html',
  styleUrls: ['./category-slider.component.css']
})
export class CategorySliderComponent implements OnInit {

  @Input() slides = [];
  @Input() headingName;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  slick_slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    speed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  slideConfig: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      940: {
        items: 5
      },
      1024: {
        items: 5
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (!this.slickModal.initialized) {
      this.slickModal.initSlick();
    }
    else if (this.slickModal.initialized) {
      this.slickModal.unslick();
    }
  }

}
