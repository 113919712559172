import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-india-news-block',
  templateUrl: './india-news-block.component.html',
  styleUrls: ['./india-news-block.component.css'],
})
export class IndiaNewsBlockComponent implements OnInit {
  @Input() slide;
  constructor(
    public filePathsService: FilePathsService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onRedirecting(url: string) {
    this.router.navigate([url]);
  }
}
