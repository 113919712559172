<div class="row outerunderbanner_row">
  <div class="container">
    <div class="row innerunderbanner_row">
      <div class="col-md-8 tripura_cont no-gutters">
        <app-show-category-list
          [pos_1]="pos_1"
          [pos_2]="pos_2"
          [count_of_post]="count_of_post"
          [slides]="post_slides"
          [categoryName]="categoryName"
        ></app-show-category-list>

        <div class="row gallery_start" *ngIf="album_slides.length != 0">
          <app-category-slider
            [headingName]="'Gallery'"
            [slides]="album_slides"
          ></app-category-slider>
        </div>
        <div class="row gallery_start" *ngIf="video_slides.length != 0">
          <app-category-slider
            [headingName]="'Videos'"
            [slides]="video_slides"
          ></app-category-slider>
        </div>
      </div>
      <div class="col-md-4 social_height">
        <div
          class="
            col-md-12
            no-gutters
            sports_outer
            for_secpndery_page_side_bar
            static_sidebar_cont
          "
        >
          <app-small-life-style
            [category_id]="11"
            [no_of_posts]="4"
          ></app-small-life-style>
        </div>
        <app-stay-connected></app-stay-connected>
      </div>
    </div>
  </div>
</div>
