import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-small-gallery-slide',
  templateUrl: './small-gallery-slide.component.html',
  styleUrls: ['./small-gallery-slide.component.css']
})
export class SmallGallerySlideComponent implements OnInit {

  @Input() slide;
  constructor(public filePathsService: FilePathsService, private router: Router) { }

  ngOnInit(): void {
  }

  onRedirecting(url: string){
    this.router.navigate([url]);
  }

}
