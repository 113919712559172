import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { FetchPostDataService } from '../services/fetch-post-data.service';
import { FilePathsService } from '../services/file-paths.service';
import { MetaService } from '../services/meta-service.service';

@Component({
  selector: 'app-single-post-page',
  templateUrl: './single-post-page.component.html',
  styleUrls: ['./single-post-page.component.css'],
})
export class SinglePostPageComponent implements OnInit {
  slug_url: string;
  post_data;
  relatedPostData = [];

  constructor(
    private route: ActivatedRoute,
    private fetchPostDataService: FetchPostDataService,
    public filePathsService: FilePathsService,
    public sanitizer: DomSanitizer,
    private meatService: MetaService
  ) {}
  
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.slug_url = params['slug_url'];
      this.default();
    });

  }

  default() {
    this.fetchPostDataService.getPostBySlug(this.slug_url).subscribe((data) => {
      this.post_data = data[0];

      this.meatService.updateTitle(this.post_data.meta_title);
      this.meatService.updateMetaInfo(
        this.post_data.meta_description,
        this.post_data.keywords
      );

      if (this.post_data['images'] != null) {
        this.post_data['images'] = this.fetchPostDataService.arrayConvertion(
          this.post_data['images']
        );
      }
      if (this.post_data['videos'] != null) {
        this.post_data['videos'] = this.fetchPostDataService.arrayConvertion(
          this.post_data['videos']
        );
      }

      this.post_data['cats'] = this.fetchPostDataService.arrayConvertion(
        this.post_data['cats']
      );

      if (this.post_data.video_category == 2) {
        this.post_data.videos[0].video_name =
          this.filePathsService.youtube_parser(
            this.post_data.videos[0].video_name
          );
        this.post_data.videos[0].video_name =
          'https://www.youtube.com/embed/' +
          this.post_data.videos[0].video_name +
          '?enablejsapi=1&version=3&playerapiid=ytplayer';
        this.post_data.videos[0].video_name = <SafeResourceUrl>(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            this.post_data.videos[0].video_name
          )
        );
      }

      this.fetchPostDataService.categorypostdata.subscribe((x) => {
        if (x) {
          this.getCategorydata();
        }
      });
    });

    this.fetchPostDataService.getNavMenu().subscribe((data) => {
      document.querySelector('#add-snippet').innerHTML = data.add_snippet;
    })
  }

  getCategorydata(data = this.fetchPostDataService.categorypostdataValue) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]['id'] == this.post_data['cats'][0]['category_id']) {
        let posts = this.fetchPostDataService.arrayConvertion(
          data[i]['category_posts']
        );
        if (posts.length > 5) {
          var maxLength = 5;
        } else {
          var maxLength = <number>posts.length;
        }
        for (let j = 0; j < maxLength; j++) {
          if (posts[j]['images'] != null) {
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(
              posts[j]['images']
            );
          }
          this.relatedPostData.push(posts[j]);
        }
      }
    }
  }
}
