import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fashion-lifestyle-sports',
  templateUrl: './fashion-lifestyle-sports.component.html',
  styleUrls: ['./fashion-lifestyle-sports.component.css']
})
export class FashionLifestyleSportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
