<div class="default-state" [ngClass]="{'loaded': load_timer === true}">
  <div id="preloader">
      <app-preloader></app-preloader>
  </div>
  <div id="displayed-content">
    <app-header></app-header>
    <div class="body_wrapper">
      <app-breaking-news></app-breaking-news>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
