import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FetchPostDataService } from '../services/fetch-post-data.service';

@Component({
  selector: 'app-breaking-news',
  templateUrl: './breaking-news.component.html',
  styleUrls: ['./breaking-news.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BreakingNewsComponent implements OnInit {
  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;

  slides = [];

  customOptions: OwlOptions = {
    loop: true,
    items: 1,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplayHoverPause: true,
    autoplaySpeed: 2000,
    navSpeed: 900,
    navText: ['', ''],
   
  };



  // slideConfig = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   autoplay: true,
  //   pauseOnHover: true,
  //   fade: true,
  //   autoplaySpeed: 5000,
  //   speed: 1500,
  // };

  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }

  loader = true;
  zone_checker = false;
  constructor(
    private fetchPostDataService: FetchPostDataService,
    private router: Router,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.fetchPostDataService.blockpostdata.subscribe((x) => {
      if (x) {
        this.getPostdata(x);
        this.loader = false;
        if(!this.zone_checker){
          this.zone.run(() => {
            this.zone_checker = true;
            this.ngOnInit();
          });
        }
        
      }
    });
  }

  getPostdata(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]['block_name'] == 'Breaking News') {
        let posts = this.fetchPostDataService.arrayConvertion(
          data[i]['block_posts']
        );

        for (let j = 0; j < posts.length; j++) {
          posts[j]['images'] = this.fetchPostDataService.arrayConvertion(
            posts[j]['images']
          );

          this.slides.push(posts[j]);
        }
      }
    }
    console.log(this.slides);
  }

  redirectPage(url) {
    console.log(url);
    
    this.router.navigate([url]);
  }
}
