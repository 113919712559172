import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-featured-video',
  templateUrl: './featured-video.component.html',
  styleUrls: ['./featured-video.component.css'],
})
export class FeaturedVideoComponent implements OnInit {
  slideConfig: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 2
      },
      940: {
        items: 5
      },
      1024: {
        items: 5
      }
    }
  }

  loader = true;
  slides = [];
  categoryName;

  constructor(
    private fetchPostDataService: FetchPostDataService,
    public filePathsService: FilePathsService
  ) {}

  ngOnInit(): void {
    this.fetchPostDataService.videopostdata.subscribe((x) => {
      if (x) {
        this.getCategorydata();
        this.loader = false;
      }
    });
  }

  getCategorydata(data = this.fetchPostDataService.videopostdataValue) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]['images'] != null) {
        data[i]['images'] = this.fetchPostDataService.arrayConvertion(
          data[i]['images']
        );
      }
    }
    this.slides = data;
  }
}
