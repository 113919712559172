import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-small-blocks',
  templateUrl: './small-blocks.component.html',
  styleUrls: ['./small-blocks.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SmallBlocksComponent implements OnInit {

  @Input() blockName: string;
  @Input() indexValue: string;
  @Input() slides =[];
  @Input() img_index = 0;

  imagePath: string;

  constructor(public filePathsService: FilePathsService) { }

  
  ngOnInit(): void {
    this.imagePath = this.filePathsService.serverImagePath;
  }
  
}
