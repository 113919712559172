<div class="row outerunderbanner_row">
  <div class="row innerunderbanner_row">
    <div class="col-md-8 tripura_cont no-gutters">
      <div class="heading">
        <h2><span>tripura featured</span></h2>
        <hr />
      </div>
      <div class="row inner_tripura_featured" *ngIf="slides != null">
        <div class="col-md-6 col-xs-6 small_banner_cont">
          <app-small-blocks
            [blockName]="'tipura-featured'"
            [slides]="slides"
            [indexValue]="0"
          ></app-small-blocks>
        </div>
        <div
          class="col-md-6 col-xs-6 small_banner_cont"
          *ngIf="slides.length > 1"
        >
          <app-small-blocks
            [blockName]="'tipura-featured'"
            [slides]="slides"
            [indexValue]="1"
          ></app-small-blocks>
        </div>
        <div class="row tripura12_post">
          <div
            class="col-md-6 col-xs-6 no-gutters tripura12_post_inner"
            *ngFor="let in of counter(12); let i = index"
          >
            <app-small-feature-block
              [slides]="slides"
              [indexValue]="i + 2"
            ></app-small-feature-block>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 social_height">
      <app-stay-connected></app-stay-connected>
    </div>
  </div>
</div>
