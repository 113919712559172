<div class="row banneront" *ngIf="!loader">
  <div class="col-md-2 small_banner_cont no-gutters rightside_ban_col" id="rightside_ban_col">
    <app-block-selection
      [blockName]="'business'"
      [slides]="postData['Business']"
      [blockType]="blockType['Business']"
    ></app-block-selection>
    <app-block-selection
      [blockName]="'fashion'"
      [slides]="postData['Fashion']"
      [blockType]="blockType['Fashion']"
    ></app-block-selection>
  </div>
  <div class="col-md-6 large_banner_cont no-gutters big_mid_ban_col">
    <app-block-selection
      [blockName]="'travel'"
      [slides]="postData['Travel']"
      [blockType]="blockType['Travel']"
    ></app-block-selection>
  </div>
  <div class="col-md-2 small_banner_cont no-gutters leftside_ban_col">
    <app-block-selection
      [blockName]="'tripura'"
      [slides]="postData['Tripura']"
      [blockType]="blockType['Tripura']"
    ></app-block-selection>
    <app-block-selection
      [blockName]="'students'"
      [slides]="postData['Students']"
      [blockType]="blockType['Students']"
    ></app-block-selection>
  </div>
  <div class="col-md-2 small_banner_cont no-gutters leftside_last_ban_col">
    <app-block-selection
      [blockName]="'nature'"
      [slides]="postData['Nature']"
      [blockType]="blockType['Nature']"
    ></app-block-selection>
    <app-block-selection
      [blockName]="'sports'"
      [slides]="postData['Sports']"
      [blockType]="blockType['Sports']"
    ></app-block-selection>
  </div>
</div>
