<app-small-blocks
  *ngIf="slides==null || blockType=='Single Post'"
  [blockName]="blockName"
  [slides]="slides"
  [indexValue]="0"
></app-small-blocks>
<app-slider-block
  *ngIf="blockType=='Post Slider'"
  [blockName]="blockName"
  [slides]="slides"
></app-slider-block>
<app-video-blocks
  *ngIf="blockType=='Post Video'"
  [blockName]="blockName"
  [slides]="slides"
  [indexValue]="0"
></app-video-blocks>
