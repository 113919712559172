import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-category-list',
  templateUrl: './show-category-list.component.html',
  styleUrls: ['./show-category-list.component.css']
})
export class ShowCategoryListComponent implements OnInit {

  @Input() slides = [];
  @Input() categoryName;
  @Input() pos_1 = [];
  @Input() pos_2 = [];
  @Input() count_of_post = 0;

  constructor() { }

  ngOnInit(): void {
  }

  counter(i: number) {
    if(this.slides.length<i){
      return new Array(this.slides.length);
    }
    return new Array(i);
  }

}
