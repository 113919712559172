import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FetchPostDataService } from 'src/app/services/fetch-post-data.service';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-world-news',
  templateUrl: './world-news.component.html',
  styleUrls: ['./world-news.component.css'],
})
export class WorldNewsComponent implements OnInit {
  slideConfig: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplayHoverPause: true,
    autoplaySpeed: 1000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      940: {
        items: 5,
      },
      1024: {
        items: 5,
      },
    },
  };

  slides = [];
  categoryName;
  loader = true;
  constructor(
    private fetchPostDataService: FetchPostDataService,
    public filePathsService: FilePathsService
  ) {}

  ngOnInit(): void {
    this.fetchPostDataService.categorypostdata.subscribe((x) => {
      if (x) {
        this.getCategorydata();
        this.loader = false;
      }
    });
  }

  getCategorydata(data = this.fetchPostDataService.categorypostdataValue) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]['id'] == '10') {
        this.categoryName = data[i]['name'];
        let posts = this.fetchPostDataService.arrayConvertion(
          data[i]['category_posts']
        );
        for (let j = 0; j < posts.length; j++) {
          if (posts[j]['images'] != null) {
            posts[j]['images'] = this.fetchPostDataService.arrayConvertion(
              posts[j]['images']
            );
          }
        }
        this.slides = posts;
      }
    }
  }
}
