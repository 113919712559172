<div class="heading">
  <h2>
    <span>{{ categoryName }}</span>
  </h2>
  <hr />
</div>
<div class="row inner_tripura_featured category-list">
  <div
    class="col-md-6 col-xs-6 small_banner_cont no-gutters"
    *ngIf="pos_1.length != 0"
  >
    <app-small-blocks
      [blockName]="'tipura-featured'"
      [slides]="pos_1"
      [indexValue]="0"
    ></app-small-blocks>
  </div>
  <div
    class="col-md-6 col-xs-6 small_banner_cont no-gutters"
    *ngIf="pos_2.length != 0"
  >
    <app-small-blocks
      [blockName]="'tipura-featured'"
      [slides]="pos_2"
      [indexValue]="0"
    ></app-small-blocks>
  </div>
  <div class="row tripura12_post">
    <div
      class="col-md-6 col-xs-6 no-gutters tripura12_post_inner"
      *ngFor="let in of counter(14); let i = index"
    >
      <app-small-feature-block
        [slides]="slides"
        [indexValue]="i"
      ></app-small-feature-block>
    </div>
  </div>
</div>
