import { Component, Input, OnInit } from '@angular/core';
import { FilePathsService } from 'src/app/services/file-paths.service';

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.css']
})
export class PostDetailsComponent implements OnInit {

  @Input() post_data;
  constructor(public filePathsService: FilePathsService) { 
    
  }

  ngOnInit(): void {
    console.log(this.post_data)
  }

}
